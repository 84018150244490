<template>
    <v-container>
        <v-btn @click="showLookUpDialog" text>{{orderNo === null || orderNo === "" ?
            $vuetify.lang.t('$vuetify.receiving.chooseOrder') : orderNo}}
        </v-btn>
        /
        <v-btn @click="chooseProduct" text>{{productName === null || productName === "" ? $vuetify.lang.t('$vuetify.receiving.chooseProduct')  :
            productName}}
        </v-btn>
        <v-dialog v-model="lookUpDialog" width="80%" :fullscreen="$vuetify.breakpoint.xsOnly" persistent>
            <v-card>
                <v-card-title>
                    {{$vuetify.lang.t('$vuetify.receiving.lookUpForOrder')}}
                </v-card-title>
                <v-card-text>
                    <v-text-field
                            v-model="purchaseOrderNumber"
                            hide-details
                            single-line
                            :label="$vuetify.lang.t('$vuetify.receiving.orderNoLabel')"
                    >
                    </v-text-field>
                </v-card-text>
                <v-card-subtitle>
                    {{$vuetify.lang.t('$vuetify.receiving.recentOrder')}}
                </v-card-subtitle>
                <v-card-text>
                    <v-chip class="ma-2" v-for="recentOrder in recentOrdersNums" v-bind:key="recentOrder"
                            @click.stop="chooseRecent(recentOrder)">
                        {{recentOrder}}
                    </v-chip>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-actions>
                    <v-btn text small @click.stop="lookupPurchase" class="primary">
                        {{$vuetify.lang.t('$vuetify.receiving.lookUp')}}
                    </v-btn>
                    <v-divider vertical class="pl-1"></v-divider>
                    <v-btn text small @click.stop="lookUpDialog = !lookUpDialog">
                        {{$vuetify.lang.t('$vuetify.receiving.cancel')}}
                    </v-btn>
                    <v-spacer></v-spacer>

                    <v-btn @click="showCreateDialog" text small class="primary"> {{$vuetify.lang.t('$vuetify.receiving.createNew')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="createDialog" persistent>
            <v-card>
                <v-card-title>
                    {{$vuetify.lang.t('$vuetify.receiving.createNewPurchaseOrder')}}
                </v-card-title>
                <v-card-text>
                    <v-text-field
                            :label="$vuetify.lang.t('$vuetify.receiving.orderNoLabel')"
                            v-model="newPurchaseData.purchaseOrderNumber"
                            :rules="[() => !!newPurchaseData.purchaseOrderNumber || 'This field is required']"
                            append-icon="mdi-camera"
                    />
                    <v-text-field
                            :label="$vuetify.lang.t('$vuetify.receiving.invoiceNumber')"
                            v-model="newPurchaseData.invoiceNumber"
                            append-icon="mdi-camera"
                    />
                    <v-select
                            v-model="newPurchaseData.vendorId"
                            :items="vendors"
                            :rules="[() => !!newPurchaseData.vendorId || 'This field is required']"
                            :label="$vuetify.lang.t('$vuetify.receiving.vendor')"
                    ></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="createNewPurchase" small color="primary">{{$vuetify.lang.t('$vuetify.receiving.submit')}}</v-btn>
                    <v-btn small text @click.prevent.stop="createDialog = !createDialog">{{$vuetify.lang.t('$vuetify.receiving.cancel')}}</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </v-container>
</template>

<script>
    export default {
        props: ["orderNo", "vendors", "productName"],
        data: () => ({
            createDialog: false,
            lookUpDialog: false,
            collapseShow: false,
            purchaseOrderNumber: "",
            purchaseOrderId: "",
            newPurchaseData: {},
            recentOrdersNums: []
        }),
        mounted() {
            if (this.$props.orderNo == null || this.$props.orderNo === "") {
                this.lookUpDialog = true;
            } else {
                this.lookUpDialog = false;
            }
        },
        created() {
            this.$axios.get(
                "purchase/recent", {
                    params: {
                        size: 10
                    }
                }
            ).then((response) => {
                if (response.data.code != 200) {
                    let message = response.data.message;
                    this.$store.dispatch('messages/addMessage', {message, messageClass: 'danger'});
                    return;
                } else {
                    this.recentOrdersNums = response.data.data;
                }
            }).catch((error) => {
                console.error(error)
            });
        },
        methods: {
            showLookUpDialog() {
                this.createDialog = false;
                this.lookUpDialog = true;
            },
            showCreateDialog() {
                this.lookUpDialog = false;
                this.createDialog = true;
            },
            createNewPurchase() {
                this.$axios.put(
                    "purchase",
                    this.newPurchaseData
                ).then((response) => {
                    let message = response.data.message;
                    if (response.data.code != 200) {
                        this.$store.dispatch('messages/addMessage', {message, messageClass: 'danger'});
                        return;
                    } else {
                        this.purchaseOrderId = response.data.data;
                        this.createDialog = false;
                        this.lookupPurchase();
                    }
                }).catch((error) => {
                        console.error(error)
                    }
                );
            },
            lookupPurchase() {
                this.$axios.get(
                    "purchase", {
                        params: {
                            id: this.purchaseOrderId,
                            purchaseOrderNumber: this.purchaseOrderNumber
                        }
                    }
                ).then((response) => {
                    if (response.data.code != 200) {
                        let message = response.data.message;
                        this.$store.dispatch('messages/addMessage', {message, messageClass: 'danger'});
                        return;
                    } else {
                        this.$emit('updatePurchase', response.data.data);
                        this.lookUpDialog = false;
                        this.purchaseOrderId = "";
                    }
                }).catch((error) => {
                    console.error(error)
                });
            },
            chooseRecent(item) {
                this.purchaseOrderNumber = item;
                this.purchaseOrderId = "";
                this.lookupPurchase();
            },
            chooseProduct() {
                this.$emit('chooseProduct');
            }
        }
    }
</script>
